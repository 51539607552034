import { request, noTimeOutReq } from '@/utils/request.js'

//获取一份试卷
export function getOneTestApi (examId, operationTestId) {
  return request({
    url: `/exam/score/test/${examId}/${operationTestId}/get`,
    method: 'post'
  })
}

//试阅卷实操评分
export function updateScoreMarkApi (data) {
  return request({
    url: `/exam/score/test/mark/update`,
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    data: data
  })
}



