<template>
  <div id="test-marking-content">
    <el-row id="marking-content-header">
      <el-input
        placeholder
        v-model="examInfo.examName"
        style="width: 600px"
        :disabled="true"
      >
        <template slot="prepend">当前考试</template>
      </el-input>
      <!-- <el-button v-throttle  type="primary" icon style="margin-left:20px">查看规则</el-button> -->
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-refresh"
        @click="refresh"
        style="float: right"
        >刷新</el-button
      >
      <el-button
        v-if="showTestPaperPool"
        style="float: right; margin-right: 20px"
        v-throttle
        icon="el-icon-s-operation"
        type="primary"
        @click="handleSetTestMark"
        >{{ testPaperBtnName }}</el-button
      >
    </el-row>

    <el-row class="test-marking-info">
      <el-col :span="24">
        <el-card shadow="always">
          <el-row>
            <el-col :md="8" :lg="5" :xl="4">
              <el-row>
                <el-col :span="6">
                  <div>
                    <img src="./img/paperTotal.png" alt />
                  </div>
                </el-col>
                <el-col :span="12" class="test-marking-box">
                  <div>试卷总数</div>
                  <div>
                    <span>{{ examOperationResult.total }}</span>
                    <span>份</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <el-col :md="8" :lg="4" :xl="4">
              <el-row>
                <el-col :span="6">
                  <div>
                    <img src="./img/evaluated.png" alt />
                  </div>
                </el-col>
                <el-col :span="12" class="test-marking-box">
                  <div>已试评</div>
                  <div>
                    <span>{{ examOperationResult.markTestTotal }}</span>
                    <span>份</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>

            <el-col :md="8" :lg="4" :xl="4">
              <el-row>
                <el-col :span="6">
                  <div>
                    <img src="./img/evaluationRate.png" alt />
                  </div>
                </el-col>
                <el-col :span="10" class="test-marking-box">
                  <div>试评率</div>
                  <div>
                    <span>{{ examOperationResult.markTestRate }}</span>
                    <span>%</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px">
      <!-- ,'backgroundColor':'#dfe6ec' -->
      <el-card shadow="always">
        <el-table
          v-loading="loading"
          stripe
          border
          ref="markTeacherTable"
          :data="examOperationResult.markTeacherCounts"
          tooltip-effect="dark"
          style="width: 100%"
          show-summary
          :summary-method="Summaries"
          header-align="center"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
        >
          <el-table-column
            align="center"
            prop="teacherName"
            label="评卷教师"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="markTestTotal"
            label="评卷数量"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="teacherAverage"
            label="平均分"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-card>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-card shadow="always">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          v-loading="loading"
          stripe
          border
          ref="perationTestTable"
          :data="examOperationResult.operationTestCounts"
          tooltip-effect="dark"
          style="width: 100%"
          show-summary
          :summary-method="Summaries"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
        >
          <el-table-column
            align="center"
            prop="operationTestName"
            label="题目名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="markTestTotal"
            label="评卷数量"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="operationTestAverage"
            label="平均分"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-card>
    </el-row>

    <el-row class="start-marking-content">
      <el-form ref="form" :model="operationTest">
        <el-form-item label="选择题目" class="formItemBoxStyle">
          <el-select
            v-model="operationTest.operationTestId"
            placeholder="请选择题目"
            @change="operationTestChange"
          >
            <el-option
              v-for="scoreQuestion in scoreQuestionList"
              :key="scoreQuestion.operationTestId"
              :value="scoreQuestion.operationTestId"
              :label="scoreQuestion.operationTestName"
            >
              <div>
                {{ scoreQuestion.operationTestName }}
                (剩余{{ scoreQuestion.num }}份)
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          v-throttle
          type="primary"
          icon="el-icon-tickets"
          size="medium"
          :disabled="operationTestDisabled"
          @click="startMarkingTest"
          >开始试评卷</el-button
        >
      </el-form>
    </el-row>

    <el-row v-if="showTestPaperPool">
      <div style="margin-bottom: 20px">
        <span style="font-size: 18px; font-weight: bold; margin-right: 10px"
          >试评卷池</span
        >
        <el-tooltip
          class="item"
          effect="dark"
          content="开启固定试评卷后，老师仅可评以下所加入的试卷"
          placement="bottom"
        >
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
      <el-card shadow="always">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          stripe
          border
          ref="testPaperList"
          :data="testPaperList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            label="考题"
            show-overflow-tooltip
            prop="operationTestName"
          ></el-table-column>
          <el-table-column
            label="考生名称"
            show-overflow-tooltip
            width="300"
            prop="examineeName"
          ></el-table-column>
          <el-table-column
            label="评分次数"
            show-overflow-tooltip
            width="200"
            prop="markCount"
          >
          </el-table-column>
          <el-table-column
            label="平均分"
            show-overflow-tooltip
            width="200"
            prop="avgScore"
          >
          </el-table-column>
          <el-table-column label="操作" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button
                v-throttle
                size="mini"
                type="primary"
                @click="handleCheckTestPaper(scope.row)"
                >查看试卷</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-row>

    <el-row v-if="showTestPaperPool" style="margin: 20px 0">
      <el-pagination
        @size-change="handleTestPaperPoolSizeChange"
        @current-change="handleTestPaperPoolCurrentChange"
        :current-page="testPaperPoolpage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="testPaperPoolpage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="testPaperPoolpage.totalSize"
      ></el-pagination>
    </el-row>

    <el-row>
      <p style="font-size: 18px; font-weight: bold">评议池</p>
      <el-form
        ref="commentPoolQueryParamRef"
        :inline="true"
        :model="commentPoolQueryParam"
      >
        <el-form-item label="评分时间" class="formItemBoxStyle">
          <el-date-picker
            v-model="markTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="markTimeChange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="评卷人" class="formItemBoxStyle">
          <el-select
            v-model="commentPoolQueryParam.markTeacher"
            placeholder="请选择评卷人"
            clearable
            @change="markTeacherChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="markTeacher in commentPoolQueryResult.markTeachers"
              :key="markTeacher.teacherId"
              :value="markTeacher.teacherId"
              :label="markTeacher.teacherName"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择题目" class="formItemBoxStyle">
          <el-select
            v-model="commentPoolQueryParam.operationTestId"
            placeholder="请选择题目"
            clearable
            style="width: 300px"
            @change="operationTesChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="operationTest in commentPoolQueryResult.operationTests"
              :key="operationTest.operationTestId"
              :label="operationTest.operationTestName"
              :value="operationTest.operationTestId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="commentPoolQueryParam.markStatus"
            placeholder="请选择状态"
            clearable
            @change="markStatusChange"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="markStatus in commentPoolQueryResult.markStatuses"
              :key="markStatus.status"
              :label="markStatus.description"
              :value="markStatus.status"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            icon="el-icon-refresh"
            @click="refresh"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-card shadow="always">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          v-loading="commentPoolLoading"
          stripe
          border
          ref="paperMarkingTable"
          :data="commentPoolList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="gmtRating"
            label="评分时间"
            show-overflow-tooltip
            :width="300"
            :formatter="customFormatter"
          ></el-table-column>
          <el-table-column
            prop="teacherName"
            label="评卷人"
            show-overflow-tooltip
            :width="160"
          ></el-table-column>
          <el-table-column
            prop="operationTestName"
            label="题目名称"
            show-overflow-tooltip
            :min-width="140"
          ></el-table-column>
          <el-table-column
            prop="markStatus"
            label="评卷状态"
            show-overflow-tooltip
            :width="80"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.markStatus == 21">已评议</span>
              <span v-if="scope.row.markStatus == 22">未评议</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" :min-width="20" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button
                v-throttle
                v-if="markTeacher.teacherType == 3"
                size="mini"
                type="primary"
                @click="
                  lookRecord(
                    scope.row.examMarkRecordId,
                    scope.row.operationTestId
                  )
                "
                >评议</el-button
              >
              <el-button
                v-throttle
                v-if="markTeacher.teacherType == 4"
                size="mini"
                type="primary"
                @click="lookRecord(scope.row.examMarkRecordId)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>

    <!-- <el-row v-if="markTeacher.teacherType == 3">
      <p style="font-size: 18px; font-weight: bold">评卷详情</p>
      <el-card
        shadow="always"
        v-for="personal in personalList"
        :key="personal.teacherId"
        style="margin-bottom: 10px"
      >
        <el-table
          stripe
          border
          :data="personal.operationTestCounts"
          tooltip-effect="dark"
          style="width: 100%"
          show-summary
          :summary-method="getSummaries"
          header-align="center"
          :header-cell-style="{
            'text-align': 'center',
            background: '#DFE6EC',
          }"
        >
          <el-table-column align="center" label="评卷人" show-overflow-tooltip>
            <div>{{ personal.teacherName }}</div>
          </el-table-column>
          <el-table-column
            align="center"
            prop="operationTestName"
            label="题目名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="markNum"
            label="评卷数量"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="operationTestAverage"
            label="平均分"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </el-card>
    </el-row> -->
    <el-row class="marking-details-content" v-if="markTeacher.teacherType == 3">
      <p
        id="markingTeacher"
        style="font-size: 18px; font-weight: bold; margin-right: 10px"
      >
        组员评卷详情
      </p>
      <div>
        <el-form :inline="true">
          <el-form-item label="阅卷老师" class="formItemBoxStyle">
            <el-select
              v-model="markingCrewParam.teacherId"
              @change="
                () => {
                  this.markingCrewPage.pageIndex = 1
                  this.changVal()
                }
              "
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in commentPoolQueryResult.markTeachers"
                :key="item.teacherId"
                :label="item.teacherName"
                :value="item.teacherId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择题目" class="formItemBoxStyle">
            <el-select
              v-model="markingCrewParam.operationTestId"
              @change="
                () => {
                  this.markingCrewPage.pageIndex = 1
                  this.changVal()
                }
              "
            >
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in commentPoolQueryResult.operationTests"
                :key="item.operationTestId"
                :label="item.operationTestName"
                :value="item.operationTestId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button
            v-throttle
            type="primary"
            icon="el-icon-download"
            @click="handleDownloadMarkingDetailBtn"
          >下载</el-button>
        </el-form>
        <el-card shadow="always">
          <el-table
            v-loading="markingCrewLoading"
            stripe
            border
            ref="markingCrewData"
            :data="markingCrewData"
            tooltip-effect="dark"
            style="width: 100%"
            @sort-change="changeSort"
            header-align="center"
            :header-cell-style="{
              'text-align': 'center',
              background: '#DFE6EC',
            }"
          >
            <el-table-column
              align="center"
              label="序号"
              width="90"
              type="index"
            ></el-table-column>
            <el-table-column
              prop="teacherName"
              align="center"
              label="评卷人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operationTestName"
              label="题目名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              sortable="custom"
              width="120"
              align="center"
              prop="markCount"
              label="评卷数量"
            ></el-table-column>
            <el-table-column
              sortable="custom"
              width="120"
              align="center"
              prop="avgScore"
              label="平均分"
            ></el-table-column>
          </el-table>
        </el-card>
        <el-pagination
          style="margin-top: 10px"
          @size-change="markingCrewPageSizeChange"
          @current-change="markingCrewPageCurrentChange"
          :current-page="markingCrewPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="markingCrewPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="markingCrewPage.total"
        ></el-pagination>
      </div>
    </el-row>
    <el-row style="margin-top: 20px; text-align: center">
      <el-button v-throttle size="medium" type="primary" @click="doneMark"
        >退出评卷</el-button
      >
    </el-row>
  </div>
</template>
<script>
import {
  getTestMarkCountApi,
  getScoreTestParamApi,
  getScoreQuestionListApi,
  getCommentPoolListApi,
  getMarkTeacherApi,
  getPersonalListApi,
  removeOccupyApi,
  getTestPaperListApi,
  verifyFixTestPaperExistApi,
  getTestTeacherCountScore,
  downloadMarkingDetailApi
} from '@/api/marking/markingTest.js'
import { getOneTestApi } from '@/api/marking/markingVideo.js'
import { getLeaderTeacher } from '@/api/PadRating/PadRating.js'
import { isEmpty, formatDate } from '@/utils/util'
import { getStore, setStore, removeStore } from '@/utils/token'
export default {
  name: 'testMarking',
  data() {
    return {
      list: [],
      loading: false,
      commentPoolLoading: false,
      operationTest: {
        operationTestId: '',
      },
      markTime: [],
      commentPoolQueryParam: {
        gmtRatingStart: '',
        gmtRatingEnd: '',
        markTeacher: '',
        operationTestId: '',
        markStatus: '',
      },
      //考试信息
      examInfo: {
        examId: 0,
        examMarkStatus: 0,
        examName: '',
        examStartTime: 0,
        examStatus: 0,
        markRuleStatus: 0,
        markTypes: [],
        reading: false,
        subjectTypes: [],
      },
      examOperationResult: {
        //教师统计
        markTeacherCounts: [],
        //题目统计
        operationTestCounts: [],
        //试评率
        markTestRate: 0,
        //已试评数
        markTestTotal: 0,
        //试卷总数
        total: 0,
      },
      scoreQuestion: {},
      //阅卷考试题目
      scoreQuestionList: [],
      operationTestDisabled: true,
      //评议池参数回显
      commentPoolQueryResult: {
        markTeachers: [],
        operationTests: [],
        markStatuses: [],
        subjectType: 0,
      },
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      //阅卷教师信息
      markTeacher: {},
      //评议池
      commentPoolList: [],
      //试阅卷个人统计
      personalList: [],

      // 试评卷池
      showTestPaperPool: false,
      testPaperList: [],
      testPaperPoolpage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      testPaperBtnName: '设置试评卷',

      //
      markingCrewLoading: false,
      markingCrewData: [],
      markingCrewParam: {
        operationTestId: '',
        //  1-评卷数量 2-平均分
        paramName: '',
        // 1-升序 2-降序
        sortType: '',
        teacherId: '',
      },
      markingCrewPage: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    if (this.$route.path !== '/markingVideo/markingVideoDissent') {
      removeStore('commentPoolQueryParam')
      removeStore('page')
    }
  },
  mounted() {
    let markTestExamInfo = JSON.parse(getStore('markTestExamInfo'))
    if (!isEmpty(markTestExamInfo)) {
      this.examInfo = markTestExamInfo
    }
    if (getStore('commentPoolQueryParam')) {
      this.commentPoolQueryParam = JSON.parse(getStore('commentPoolQueryParam'))
      this.page = JSON.parse(getStore('page'))
    }
    this.init()
    this.getLoginRole()
    // this.handleVerifyTestPaper()
  },
  methods: {
    init() {
      if (isEmpty(this.examInfo.examId)) {
        return
      }
      this.removeOccupy()
    },
    //【试阅卷】解除占用
    removeOccupy() {
      removeOccupyApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.getTestMarkCount()
          this.getScoreQuestionList()
          this.getScoreTestParam()
          this.getMarkTeacher()
          this.getPersonalList()
          this.getMarkingCrewData({})
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //试评卷统计
    getTestMarkCount() {
      getTestMarkCountApi(this.examInfo.examId)
        .then((res) => {
          if (res.success) {
            this.examOperationResult = {}
            this.examOperationResult = res.data
          } else {
          }
        })
        .catch(() => {})
    },
    //获取教师试阅卷考试题目
    getScoreQuestionList() {
      getScoreQuestionListApi(this.examInfo.examId)
        .then((res) => {
          if (res.success) {
            this.scoreQuestionList = []
            this.scoreQuestionList = res.data
          }
        })
        .catch(() => {})
    },
    //试阅卷个人统计
    getPersonalList() {
      getPersonalListApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.personalList = []
          this.personalList = res.data
        }
      })
    },
    //获取评议池查询参数
    getScoreTestParam() {
      getScoreTestParamApi(this.examInfo.examId)
        .then((res) => {
          if (res.success) {
            this.commentPoolQueryResult = {}
            this.commentPoolQueryResult = res.data
          } else {
          }
        })
        .catch(() => {})
    },
    //获取评议池列表
    getCommentPoolList() {
      let data = {
        examId: this.examInfo.examId,
        gmtRatingStart: this.commentPoolQueryParam.gmtRatingStart,
        gmtRatingEnd: this.commentPoolQueryParam.gmtRatingEnd,
        teacherId: this.commentPoolQueryParam.markTeacher,
        operationTestId: this.commentPoolQueryParam.operationTestId,
        markStatus: this.commentPoolQueryParam.markStatus,
      }
      this.commentPoolLoading = true
      getCommentPoolListApi(this.page.pageIndex, this.page.pageSize, data)
        .then((res) => {
          this.commentPoolLoading = false
          if (res.success) {
            this.commentPoolList = []
            this.commentPoolList = res.data
            this.page.totalSize = 0
            this.page.totalSize = res.total
          } else {
          }
        })
        .catch(() => {})
    },
    // 获取登录账号角色
    getLoginRole() {
      getLeaderTeacher(this.examInfo.examId)
        .then((res) => {
          if (res.success) {
            this.showTestPaperPool = res.data
            this.fetchTestPaperPool()
          }
        })
        .catch((err) => {})
    },
    // 获取试评卷池
    fetchTestPaperPool() {
      // 查询已加入的
      getTestPaperListApi(
        this.testPaperPoolpage.pageIndex,
        this.testPaperPoolpage.pageSize,
        this.examInfo.examId,
        0,
        {
          enabledExamMarkRecordTest: true,
        }
      ).then((res) => {
        if (res.success) {
          this.testPaperList = res.data
          this.testPaperPoolpage.totalSize = res.total
        }
      })
    },
    // 校验固定试评卷
    // handleVerifyTestPaper() {
    //   verifyFixTestPaperExistApi(this.examInfo.examId).then((res) => {
    //     if (res.success) {
    //       this.testPaperBtnName = res.data ? '固定试评卷' : '试评卷设置'
    //     }
    //   })
    // },
    getMarkTeacher() {
      if (isEmpty(this.examInfo.examId)) {
        return
      }
      getMarkTeacherApi(this.examInfo.examId).then((res) => {
        if (res.success) {
          this.markTeacher = {}
          this.markTeacher = res.data
          this.getCommentPoolList()
        }
      })
    },
    markTimeChange() {
      let gmtRatingStart = ''
      let gmtRatingEnd = ''
      if (!isEmpty(this.markTime)) {
        if (this.markTime.length > 0) {
          gmtRatingStart = Date.parse(this.markTime[0])
          gmtRatingEnd = Date.parse(this.markTime[1])
        }
      }
      this.commentPoolQueryParam.gmtRatingStart = gmtRatingStart
      this.commentPoolQueryParam.gmtRatingEnd = gmtRatingEnd
      this.reloadCommentTable()
    },
    markTeacherChange() {
      this.reloadCommentTable()
    },
    operationTesChange() {
      this.reloadCommentTable()
    },
    markStatusChange() {
      this.reloadCommentTable()
    },
    reloadCommentTable() {
      this.page.pageIndex = 1
      this.getCommentPoolList()
    },
    refresh() {
      this.resetQueryParam()
      this.init()
    },
    resetQueryParam() {
      this.markTime = []
      this.commentPoolQueryParam.gmtRatingStart = ''
      this.commentPoolQueryParam.gmtRatingEnd = ''
      this.commentPoolQueryParam.markTeacher = ''
      this.commentPoolQueryParam.operationTestId = ''
      this.commentPoolQueryParam.markStatus = ''
    },
    //选中考题才能开始试评卷
    operationTestChange() {
      if (!isEmpty(this.operationTest.operationTestId)) {
        this.operationTestDisabled = false
      }
      this.scoreQuestionList.forEach((item) => {
        if (this.operationTest.operationTestId == item.operationTestId) {
          this.scoreQuestion = {}
          this.scoreQuestion = item
        }
      })
    },
    //开始评卷
    startMarkingTest() {
      if (this.scoreQuestion.num == 0) {
        this.msgWarning('剩余试卷为0')
        return
      }
      this.getOneTest().then((res) => {
        if (res.success) {
          if (!this.operationTestDisabled) {
            this.$router.push({
              path: `/markingVideo/markingVideo`,
              query: {
                examId: this.examInfo.examId,
                operationTestId: this.scoreQuestion.operationTestId,
              },
            })
          }
        } else {
          if (res.code != 500) {
            if (res.code == 831) {
              this.msgSuccess(res.msg)
            } else if (res.code == 817) {
              this.msgSuccess(res.msg)
            } else {
              this.msgWarning('剩余试卷被占用')
            }
          }
        }
      })
    },
    //获取一份试卷
    async getOneTest() {
      let data = {
        channel: 0,
      }
      let flag = false
      await getOneTestApi(
        this.examInfo.examId,
        this.scoreQuestion.operationTestId,
        data
      )
        .then((res) => {
          flag = res
        })
        .catch((err) => {
          flag = err
        })
      return Promise.resolve(flag)
    },
    lookRecord(examMarkRecordId, operationTestId) {
      // 存
      setStore(
        'commentPoolQueryParam',
        JSON.stringify(this.commentPoolQueryParam)
      )
      setStore('page', JSON.stringify(this.page))
      this.$router.push({
        path: `/markingVideo/markingVideoDissent`,
        query: {
          examId: this.examInfo.examId,
          examMarkRecordId: examMarkRecordId,
          operationTestId: operationTestId,
          teacherType: this.markTeacher.teacherType,
        },
      })
    },
    doneMark() {
      removeStore('markTestExamInfo')
      this.$router.push({ name: 'markingPlainManage' })
    },
    //currentPage 改变时会触发,如第一页切换到第二页
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getCommentPoolList()
    },
    //pageSize 改变时会触发,如每页10条切换到每页15条
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getCommentPoolList()
    },
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'gmtRating':
          if (isEmpty(row.gmtRating)) {
            return
          }
          return formatDate(new Date(row.gmtRating), 'yyyy-MM-dd hh:mm:ss')
      }
    },
    Summaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let values = data.map((item) => Number(item[column.property]))
        if (index == 2) {
          values = data.map((item) => Number(item['totalScore']))
          if (sums[1] == 0) {
            sums[index] = 0
            return
          }
        }
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = ''
        }
        if (index == 2) {
          sums[index] = (sums[index] / sums[1]).toFixed(1)
        }
      })
      return sums
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let values = data.map((item) => Number(item[column.property]))
        if (index == 3) {
          console.log('平均分：', data)
          values = data.map((item) => Number(item['totalScore']))
          if (sums[2] == 0) {
            sums[index] = 0
            return
          }
        }
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = ''
        }
        if (index == 3) {
          sums[index] = (sums[index] / sums[2]).toFixed(1)
        }
      })
      return sums
    },
    // 设置试评卷
    handleSetTestMark() {
      this.$router.push({
        path: `/marking/markingTest/testPaperSetting`,
        query: {
          examId: this.examInfo.examId,
          scoreQuestionList: JSON.stringify(this.scoreQuestionList),
        },
      })
    },
    // 查看试卷
    handleCheckTestPaper(row) {
      this.$router.push({
        path: `/markingVideo/markingVideo`,
        query: {
          examId: this.examInfo.examId,
          operationTestId: row.operationTestId,
          totalNum: 10000,
          currentData: JSON.stringify(row),
        },
      })
    },
    handleTestPaperPoolCurrentChange(val) {
      this.testPaperPoolpage.pageIndex = val
      this.fetchTestPaperPool()
    },
    handleTestPaperPoolSizeChange(val) {
      this.testPaperPoolpage.pageIndex = 1
      this.testPaperPoolpage.pageSize = val
      this.fetchTestPaperPool()
    },

    //
    markingCrewPageCurrentChange(val) {
      this.markingCrewPage.pageIndex = val
      this.changVal()
    },
    markingCrewPageSizeChange(val) {
      this.markingCrewPage.pagSize = val
      this.markingCrewPage.pageIndex = 1
      this.changVal()
    },
    // 排序触发
    changeSort({ column, prop, order }) {
      const sortType = {
        markCount: 1,
        avgScore: 2,
        ascending: 1,
        descending: 2,
      }
      this.markingCrewParam.paramName = sortType[prop]
      this.markingCrewParam.sortType = sortType[order]
      this.changVal()
    },
    changVal() {
      const condition = JSON.parse(JSON.stringify(this.markingCrewParam))
      for (let key in condition) {
        if (condition[key] == '' || condition[key] == undefined) {
          delete condition[key]
        }
      }
      // const condition = Object.entries(this.markingCrewParam).reduce(
      //   (newCondition, [key, value]) => {
      //     if (value !== '' && value !== undefined) {
      //       newCondition[key] = value
      //     }
      //     return newCondition
      //   },
      //   {}
      // )
      this.getMarkingCrewData(condition)
    },
    getMarkingCrewData(condition) {
      this.markingCrewLoading = true
      getTestTeacherCountScore(
        this.markingCrewPage.pageIndex,
        this.markingCrewPage.pageSize,
        this.examInfo.examId,
        condition
      )
        .then((res) => {
          if (res.success) {
            this.markingCrewData = res.data
            this.markingCrewPage.total = res.total
          }
          this.markingCrewLoading = false
        })
        .catch((err) => {
          this.markingCrewLoading = false
        })
    },
    // 下载组员评卷详情
    handleDownloadMarkingDetailBtn() {
      let params = {
        enabledExamMarkRecordTest: true,
        operationTestId: this.markingCrewParam.operationTestId ? this.markingCrewParam.operationTestId : 0,
        paramName: this.markingCrewParam.paramName ? this.markingCrewParam.paramName : 0,
        sortType: this.markingCrewParam.sortType ? this.markingCrewParam.sortType : 0,
        teacherId: this.markingCrewParam.teacherId ? this.markingCrewParam.teacherId : 0,
      }
      downloadMarkingDetailApi(this.examInfo.examId, params).then((res) => {
        if(res.success) {
          window.open(this.$imageAddress(res.data));  
          this.$message.success("下载成功");
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
}
</script>
<style lang="scss">
.router-view {
  padding: 0 20px 10px 20px;
}

#marking-content-header {
  padding: 15px 5px;
  border-left: 10px solid #09c0c8;
  background-color: rgba(242, 242, 242, 1);
}

.test-marking-info {
  margin-top: 20px;
  padding: 0 15px;
}

.test-marking-box > div:first-child {
  color: #303133;
  font-size: 17px;
  margin-left: 5px;
}

.test-marking-box > div:last-child > span:first-child {
  font-size: 37px;
  color: #09c0c8;
  position: relative;
  top: -1px;
}

.test-marking-box > div:last-child > span:last-child {
  font-size: 16px;
  color: #778ca2;
  position: relative;
  top: -4px;
  left: 3px;
}

.start-marking-content {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-marking-content > .el-form {
  text-align: center;
}

.start-marking-content
  > .el-form
  > .el-form-item:first-child
  > .el-form-item__content
  > .el-select
  > .el-input
  > .el-input__inner {
  width: 500px;
}

// #test-marking-content .el-form-item__label {
// //  padding: 0;
//   text-align: center;
//   background-color: #dfe6ec;
// }
</style>
