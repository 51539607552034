import { request, noTimeOutReq } from '@/utils/request.js'

//试评卷统计
export function getTestMarkCountApi (examId) {
  return request({
    url: `/exam/score/test/${examId}/count/get`,
    method: 'post',
  })
}

//获取评议池查询参数
export function getScoreTestParamApi (examId) {
  return request({
    url: `/exam/score/test/${examId}/query/get`,
    method: 'post',
  })
}

//获取教师试阅卷考试题目
export function getScoreQuestionListApi (examId) {
  return request({
    url: `/exam/score/test/${examId}/question/list`,
    method: 'post',
  })
}

//获取评议池列表
export function getCommentPoolListApi (pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/test/${pageIndex}/${pageSize}/markRecord/page`,
    method: 'post',
    data: data
  })
}


//获取阅卷教师信息
export function getMarkTeacherApi (examId) {
  return request({
    url: `/exam/score/${examId}/markTeacher/get`,
    method: 'post',
  })
}

//试阅卷个人统计
export function getPersonalListApi (examId) {
  return request({
    url: `/exam/score/test/${examId}/personal/count/list`,
    method: 'post',
  })
}

//【试阅卷】解除占用
export function removeOccupyApi (examId) {
  return request({
    url: `/exam/score/test/${examId}/delete`,
    method: 'post',
  })
}

// 考生试阅卷分页列表
export function getTestPaperListApi(pageIndex, pageSize, examId, operationTestId, data = {}){
  return request({
    url: `/exam/score/test/${examId}/examinee/list/${pageIndex}/${pageSize}`,
    method: 'post',
    data: {
      ...data,
      operationTestId
    }
  })
}

// 修改试阅卷状态
export function updateTestPaperStatusApi(examId, enabledExamMarkRecordTest, data){
  return request({
    url: `/exam/score/test/${examId}/update/examinee/enabled?enabledExamMarkRecordTest=${enabledExamMarkRecordTest}`,
    method: 'post',
    data
  })
}

// 校验固定试评卷是否存在
export function verifyFixTestPaperExistApi(examId){
  return request({
    url: `/exam/score/test/${examId}/verify/enabled/exist`,
    method: 'post',
  })
}

// 阅卷老师统计分页
export function getTestTeacherCountScore (pageIndex,pageSize,examId,data) {
  return request({
    url: `/exam/score/test/${examId}/personal/count/page/${pageIndex}/${pageSize}`,
    method: 'post',
    data
  })
}

// 【试评】查看试卷
export function getTestMarkingPaperApi(examId, operationTestId, examineeId) {
  return request({
    url: `/exam/score/test/detail/${examId}/${operationTestId}/${examineeId}/get`,
    method: 'post',
  })
}

// 试评卷下载组员评卷情况
export function downloadMarkingDetailApi(examId, data) {
  return request({
    url: `/exam/score/test/${examId}/personal/count/download`,
    method: 'post',
    data
  })
}